import { css } from "styled-components"
import tm from "theme"

export function mq(name) {
  const query = tm.breakpoints[name]

  if (!query) throw new Error(`Could not find size: ${name}`)
  return query
}

export function color(name) {
  const query = tm.colors[name]

  if (!query) throw new Error(`Could not find color: ${name}`)
  return query
}

export function hideAt(breakpoint) {
  return css`
    @media ${mq(breakpoint)} {
      display: none;
    }
  `
}

export function showAt(breakpoint, displayMode = "block") {
  return css`
    display: none;

    @media ${mq(breakpoint)} {
      display: ${displayMode};
    }
  `
}

export default tm
