import styled, { css } from "styled-components"

const sizeStyle = ({ size = "xl" }) =>
  ({
    xs: css`
      width: 90px;
      font-size: 12px;
      line-height: 20px;
      padding-top: 4px;
      padding-bottom: 4px;

      svg {
        font-size: 12px;
      }
    `,
    sm: css`
      width: 120px;
      font-size: 12px;
      line-height: 28px;
      padding-top: 4px;
      padding-bottom: 4px;

      svg {
        font-size: 12px;
      }
    `,
    md: css`
      width: 150px;
      font-size: 16px;
      line-height: 28px;
      padding-top: 9px;
      padding-bottom: 9px;

      svg {
        font-size: 16px;
      }
    `,
    lg: css`
      width: 225px;
      font-size: 16px;
      line-height: 28px;
      padding-top: 10px;
      padding-bottom: 10px;

      svg {
        font-size: 28px;
      }
    `,
    xl: css`
      width: 275px;
      font-size: 18px;
      line-height: 28px;
      padding-top: 10px;
      padding-bottom: 10px;

      svg {
        font-size: 28px;
      }
    `,
    xxl: css`
      width: 325px;
      font-size: 21px;
      line-height: 32px;
      padding: 13px 20px;

      svg {
        font-size: 32px;
      }
    `
  }[size])

export const baseButton = css`
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: ${({ theme }) => theme.weights.medium};
  border-radius: 28px;
  border: none;
  ${sizeStyle};
  vertical-align: middle;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  max-width: 100%;
  cursor: pointer;

  &::first-letter {
    text-transform: capitalize;
  }
`

export const PrimaryButton = styled.button`
  ${baseButton};
  color: ${({ theme }) => theme.colors.white};

  ${({ theme, blue, shadow }) =>
    blue
      ? `
        background: ${theme.colors.skyBlue};
        box-shadow: ${shadow ? "0 4px 16px 0 rgba(11, 158, 217, 0.5)" : "none"};
        border: 1px solid ${theme.colors.skyBlue};
      `
      : `
        background: ${theme.colors.deepPink};
        box-shadow: ${shadow ? "0 4px 16px 0 rgba(255, 69, 151, 0.8)" : "none"};
        border: 1px solid ${theme.colors.deepPink};
  `}
  &:hover {
    color: ${({ theme }) => theme.colors.white};
    box-shadow: none;
    ${({ theme, blue }) =>
      blue
        ? `
          background: #2d71b4;
          border-color: #2d71b4;
        `
        : `
          background: ${theme.colors.mediumVioletRed};
          border-color: ${theme.colors.mediumVioletRed};
    `}
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.lightGray};
    border-color: ${({ theme }) => theme.colors.lightGray};
    box-shadow: none;
  }
`
