import styled from "styled-components"
import { color } from "helpers/styled"
import { baseButton } from "../PrimaryButton"

// eslint-disable-next-line import/prefer-default-export
export const SecondaryButton = styled.button`
  ${baseButton};
  background: ${({ transparent }) => (transparent ? "transparent" : color("white"))};
  border: 1px solid ${({ border }) => color(border ? "deepPink" : "white")};
  box-shadow: ${({ shadow }) => (shadow ? " 0 2px 14px 0 rgba(195, 205, 224, 0.8)" : "none")};
  color: ${color("deepPink")};

  &:hover {
    background: ${color("mediumVioletRed")};
    border-color: ${color("mediumVioletRed")};
    box-shadow: none;
    color: ${color("white")};
  }

  &:disabled {
    color: ${color("gray")};
    background: ${color("paleGray")};
    border-color: ${color("paleGray")};
    box-shadow: none;
  }
`
