import { useContext } from "react"
import dynamic from "next/dynamic"
import { ModalContext } from "./ModalContext"

const CRif = dynamic(() => import("./CRif"))
const NewOpportunity = dynamic(() => import("./NewOpportunity"))
const WhatsappConsent = dynamic(() => import("./WhatsappConsent"))
const BankFlip = dynamic(() => import("./BankFlip"))

export default function GlobalModals() {
  const { show, modal } = useContext(ModalContext)

  if (!show) return null

  if (modal === "newOpportunity") return <NewOpportunity />
  if (modal === "whatsappConsent") return <WhatsappConsent />
  if (modal === "crif") return <CRif />
  if (modal === "bankflip") return <BankFlip />

  return null
}
